import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import router from "./router";
import "./permission";

import App from "./App.vue";
import "./styles/common.scss";
import { url } from "@/config";
import "@/mock/";
//注册自定义组件
import "./components/";
//导入主题文件
import "@/theme/index.js";

import { parseTime, resetForm, addDateRange, selectDictLabel, download, compress, imageUrlToBase64, setFilename ,dataURItoBlob} from '@/utils/common';

Vue.config.productionTip = false;

Vue.prototype.url = url;

Vue.prototype.msgSuccess = function(msg) {
    this.$message({ showClose: true, message: msg, type: "success" });
};

Vue.prototype.msgError = function(msg) {
    this.$message({ showClose: true, message: msg, type: "error" });
};

Vue.prototype.msgWarn = function(msg) {
    this.$message({ showClose: true, message: msg, type: "warning" });
};

Vue.prototype.msgInfo = function(msg) {
    this.$message.info(msg);
};
Vue.prototype.setFilename = setFilename;
Vue.prototype.imageUrlToBase64 = imageUrlToBase64;
Vue.prototype.dataURItoBlob = dataURItoBlob;

// moment 时间格式化
import moment from 'moment/moment';
Vue.prototype.moment = moment;
Vue.use(window.AVUE, {
    size: "mini",
});
Vue.use(ElementUI);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
