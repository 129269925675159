import { login, logout, getInfo } from "@/api/login";
import { getToken, setToken, removeToken, removeUserInfo } from "@/utils/auth";

const user = {
    state: {
        token: getToken(),
        name: "",
        userName: "",
        nickName: "",
        phonenumber: "",
        avatar: "",
        roles: [],
        permissions: [],
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_NICKNAME: (state, name) => {
            state.nickName = name;
        },
        SET_USERNAME: (state, name) => {
            state.userName = name;
        },
        SET_CUSTOMERNAME: (state, name) => {
            state.customerName = name;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions;
        },
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const username = userInfo.username.trim();
            const password = userInfo.password;
            const code = userInfo.code;
            const uuid = userInfo.uuid;
            return new Promise((resolve, reject) => {
                login(username, password, code, uuid)
                    .then((res) => {
                        if (res.code === 200) {
                            setToken(res.data.token);
                            commit("SET_TOKEN", res.data.token);
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 获取用户信息
        GetInfo({ dispatch, commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo(state.token)
                    .then((res) => {
                        const user = res.data.user;
                        const customerName = res.data.customerName;
                        // 验证返回的roles是否是一个非空数组
                        if (res.data.roles && res.data.roles.length > 0) {
                            commit("SET_ROLES", res.data.roles);
                            commit("SET_PERMISSIONS", res.data.permissions);
                        } else {
                            commit("SET_ROLES", ["ROLE_DEFAULT"]);
                        }
                        commit("SET_CUSTOMERNAME", customerName);

                        dispatch("SetUserInfo", user);
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        SetUserInfo({ commit, state }, user) {
            commit(
                "SET_AVATAR",
                user.avatar || process.env.VUE_APP_BASE_API + user.avatar
            );
            commit("SET_NAME", user.nickName);
            commit("SET_USERNAME", user.userName);
            commit("SET_NICKNAME", user.nickName);
        },
        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                    .then(() => {
                        commit("SET_TOKEN", "");
                        commit("SET_ROLES", []);
                        commit("SET_PERMISSIONS", []);
                        removeToken();
                        removeUserInfo();
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 前端 登出
        FedLogOut({ commit }) {
            commit("SET_TOKEN", "");
            removeToken();
            removeUserInfo();
        },
    },
};

export default user;
