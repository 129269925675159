import router from "./router";
// import store from './store';
import { Message } from "element-ui";
import NProgress from "nprogress";
// import 'nprogress/nprogress.css';
import { getToken } from "@/utils/auth";

NProgress.configure({ showSpinner: false });

const whiteList = ["/login", "/auth-redirect", "/bind", "/register", "/view"];

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (getToken()) {
        /* has token*/
        if (to.path === "/login") {
            next({ path: "/" });
            NProgress.done();
        } else {
            next();
        }
    } else {
        console.log('to.path :>> ', to);
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next();
        } else {
            // 否则全部重定向到登录页
            next(`/login?redirect=${to.path}`);
            NProgress.done();
        }
    }
});

router.afterEach(() => {
    NProgress.done();
});
