/**
 * @file request.js
 */
import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const request = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 2000000,
});
// request拦截器
request.interceptors.request.use(
    (config) => {
        if (getToken()) {
            config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

window.axios.interceptors.request.use(
    (config) => {
        if (getToken()) {
            config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.baseURL = process.env.VUE_APP_BASE_API;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 响应拦截器
request.interceptors.response.use(
    (res) => {
        const code = res.data.code;
        if (code === 401) {
            MessageBox.confirm(
                "登录状态已过期，您可以继续留在该页面，或者重新登录",
                "系统提示",
                {
                    confirmButtonText: "重新登录",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                store.dispatch("FedLogOut");
                location.reload(); // 为了重新实例化vue-router对象 避免bug
            });
        } else {
            return res.data;
        }
    },
    (error) => {
        Message({
            message: error.message,
            type: "error",
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    }
);
export default request;
