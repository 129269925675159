/**
 * @file 腾讯云上传图片
 */
import request from '@/utils/request';
import COS from 'cos-js-sdk-v5';


// 上传图片获取签名
export function getSign() {
    return request({
        url: '/business/app/version/test',
        method: 'post',
    });
}
/**
 * 上传腾讯云
 * @param {*} folder 文件目录 模块名称
 * @param {*} filename 文件名称 时间错+随机数
 * @param {*} file 文件对象
 */
// sign: "q-sign-algorithm=sha1&q-ak=AKIDuYv30hfgyaw2sRvwuc6LJ1SfmSsfjwOb&q-sign-time=1587893026;1587896626&q-key-time=1587893026;1587896626&q-header-list=&q-url-param-list=&q-signature=46f71dc308af035c51c481d679449ba3e7d0e14b"

export function cdnUpload(name, file, sign) {
    var cos = new COS({
        getAuthorization: function (options, callback) {
            options = {
                Bucket: sign.bucket,
                region: sign.region
            };
            callback({
                TmpSecretId: sign.tmpSecretId,
                TmpSecretKey: sign.tmpSecretKey,
                XCosSecurityToken: sign.sessionToken,
                StartTime: sign.startTime,
                ExpiredTime: sign.expiredTime,
            });
        }
    });

    return new Promise(function (resolve, reject) {
        // 模拟接口调用
        resolve(cos);
    });


}
