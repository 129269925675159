import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 公共路由
export const constantRoutes = [
    {
        path: "/",
        name: "列表页",
        component: () => import(/* webpackChunkName: "page" */ "@/views/index"),
    },
    {
        path: "/build/:id",
        name: "build",
        component: () => import(/* webpackChunkName: "page" */ "@/views/build"),
    },
    {
        path: "/view/:id",
        name: "view",
        component: () => import(/* webpackChunkName: "page" */ "@/views/view"),
    },

    {
        path: "/login",
        component: () => import("@/views/login"),
        hidden: true,
    },
    {
        path: "/404",
        component: () => import("@/views/error/404"),
        hidden: true,
    },
    {
        path: "/401",
        component: () => import("@/views/error/401"),
        hidden: true,
    },
];

export default new Router({
    mode: "history", // 去掉url中的#
    scrollBehavior: () => ({
        y: 0,
    }),
    routes: constantRoutes,
});
