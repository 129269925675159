/**
 * @file login.js
 */
import request from '@/utils/request';

// 登录方法
export function login(username, password, code, uuid) {
    const data = {
        username,
        password,
        code,
        uuid
    };
    return request({
        url: '/login',
        method: 'post',
        params: data
    });
}

// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/get/manager/info',
        method: 'get'
    });
}

// 退出方法
export function logout() {
    return request({
        url: '/logout',
        method: 'post'
    });
}

// 获取验证码
export function getCodeImg() {
    return request({
        url: '/captcha/image',
        method: 'get'
    });
}

// 判断是不是超管账号接口
export function loginInfo(params) {
    return request({
        url: '/get/manager/login/info',
        method: 'get',
        params: params
    });
}

// 获取短信验证码
export function smsSend(params) {
    return request({
        url: '/common/sms/send',
        method: 'get',
        params: params
    });
}

// 短信验证码登录
export function superManagementLogin(params) {
    return request({
        url: '/common/super/management/login',
        method: 'post',
        params: params
    });
}
